
.waiter-dinning-cont{
    /* background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029; */
    border-radius: 4px;
    margin:15px 0px
}
.waiter-dinning{
    margin:10px 
}
.makeStyles-content-10 {background-color: #FFFFFF;}
.waiter-orders{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
}
.MatToggle{
    position: absolute;
    top: 90px;
    right: 10px;
}
.waiter-table-card{
    display: flex;
    justify-content: space-between;
}
.hiddenView{
    display: none;
}

@font-face{
    font-family:mont-med;
    src:url('../../../assets/font-family/Montserrat/Montserrat-Medium.ttf')
  }
.waiter-card-text{
    color: #5c3a3a;
    font: normal normal normal 9px/11px mont-med;

}
@media print{
    .hiddenView{
        display: block;
        margin-left: 12px;
    }
    .btnOnprint{
        display: none;
    }
    .hiddenView>h1,.hiddenView>h2{
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: 900;
        align-items: center;
        text-align: center;
    }
    .pop-image{
        margin-left: 25%;
        height: 220px;
    
    }
   
}

.ToggleSwitch{
  background: linear-gradient(#ccc, #eee);
  border: 3px solid green;
  height: 2em;
  width: 5em;
  border-radius: 1em;
  margin-left: 45%;
  align-items: center;
  margin-bottom: 15px;
}
 .knob{
    position: relative;
    width: 1.9em;
    height: 1.9em;
    background: #666;
    border: 1px solid #ddd;
    border-radius: 50%;
    left: 0em;
    transition: left 0.3s ease-out;
   
 }
 .active{
     left: 3em;
 }