body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1.5rem;
    list-style: none;
    background-color: #fff;
    border-radius: 0; 
  }
  .side-nav-drawer-app{
    background-color:#F5F5F5 ;
  }
  .MuiDrawer-paper {
    background-color: #F5F5F5  !important ;
  }
  .MuiList-root{
    background-color: #F5F5F5 !important;
    color: #707070;
  }
  .MuiList-root>a:hover,.MuiList-root>a:active{
    background-color: #FF0000 !important;
    color: #fff
  }
  /* .MuiList-root>a:active,.MuiButtonBase-root>a:active, .MuiListItem-button>a:after{
    background-color: #FF0000 !important;

  } */
  /* .MuiButtonBase-root>:active,.MuiListItem-button:active{
    background-color: #FF0000 !important; */

  /* } */
 .MuiToolbar-root{
    background-color: #FF0000 !important;
  }

  
  .child-menu-test-case{
    color: #FFF !important;
  }

   @font-face{
    font-family:mont-med;
    src:url('./assets/font-family/Montserrat/Montserrat-Medium.ttf')
  }
  .MuiListItemText-root> .MuiTypography-displayBlock{
    /* font-family: "mont-reg";
    font-size: 25px/30px; */
    font: normal normal normal 18px/30px mont-med;
    /* font-family: 'mont-reg'; */
    color: #707070;
    text-transform: capitalize;
    opacity: 1;
  }
/* 
  a>.MuiListItem-button :hover{
    color: #fff  !important;
  } */
  /* .MuiList-root>a:hover{
    color:#fff !important;
  }  */
  /* .MuiListItem-root{
    color: #fff  !important;
  }  */
  /* .MuiButtonBase-root:hover{
    color: #fff  !important;
  }  */
  /* a>.MuiListItem-button :hover{
color: #fff !important;
  } */
  /* .MuiListItem-button :hover,.MuiListItem-button :active,.MuiListItem-button :focus{
    
    color: #fff  !important;  
  
} */


  
  

  