.profile-image {
    width: 96px;
    height: 96px;
    opacity: 1;
}
.profile-details{
    text-align: left;
    margin-left: 20px;
    margin-top: 22px;
    color: #ffffff;
    padding-top: -10px;
    opacity: 1;
    letter-spacing: 0;
}
.profile-icon{
    color: #ffffff;
    margin-top: 30px;
    margin-right: 30px;
}
.profile-box {
    display: flex;   
}