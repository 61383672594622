/* .delivery-order-main{ */
    /* border: 1px solid lightgray; */
/* } */
@font-face{
    font-family:mont-med;
    src:url('../../../assets/font-family/Montserrat/Montserrat-Medium.ttf')
  }
.placeOrder-header-nav{
    height: 10vh;
    background-color: #ff0000;
    text-align: center;
    color: white;
    padding: 3vh;
font-size: larger;
font-weight: 700;
}
.placeOrder-body{
    padding: 0 auto;
}
.delivery-order-input{
    margin: 25px 0;
    height: 50px;
}
.delivery-order-btn{
float: right;   
        width: 112px;
        height: 30.5px;
        color:white;
        /* padding: 10.5px 42px; */
        border-radius: 2px;
        background-image: linear-gradient(to bottom, #ff0000, rgba(128, 0, 0, 0.98) 238%);
   
}
/* .delivery-newOrder-boxContainer{

} */
.delivery-newOrder-box:hover{
    cursor: pointer;
}
.delivery-newOrder-box{
    /* height: 96px;
    margin: 26px 25px 25px;
    padding: 28px 52px 7.5px 53.5px;  
    border-radius: 2px; */
    text-align: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    color: #ff0000;
    margin: 0 10px;
    max-width: 330px;
    border-radius: 10px;

}
.delivery-orderList-top-body{
    /* opacity: 0.26; */
  border-radius: 6.5px;
}
.delivery-newOrder-line{
    height: 42px;
  margin: 105px 86px 219px 140px;
  padding: 3px 140.5px 0;
  font-family: Montserrat;
  font-size: 34.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.delivery-newOrder-icon{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 80vh;
    right: 50px;

}
.delivery-orderList-heading{
    font-size: 26.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  opacity: 1;
  text-align: left;
  color: #707070;
  font-family: mont-med;
}
.delivery-orderList-para{
    font-family: mont-med;
  font-size: 16.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.delivery-orderList-top-box{
    display:flex;
    padding:40px 20px;
    justify-content:center
}
.delivery-newOrder-iconBtn{
    width: 51px;
    height: 51px;
    margin: 219px 60px 12.5px 102px;
    padding: 4.5px;
    box-shadow: 0 5.5px 12px 0 rgba(235, 19, 19, 0.22);
    background-image: linear-gradient(to bottom, #db0000, #6e0000);
}