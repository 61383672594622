.kitchen-list-table-head{
    background-color: #707070;
    color: white;
}
.hideNShowOnPirnt{
    display: none;
}
.kitchen-orders-nav{
    display: flex;
    height: 50px;
    justify-content: space-between;
    background-color: white;
}

@font-face{
    font-family:mont-med;
    src:url('../../../assets/font-family/Montserrat/Montserrat-Medium.ttf')
  }
.kitchen-table-order-nav{
    display: flex;
    justify-content: space-between;
}
.kitchen-order-table{
    width: 100%;
}
.w-100{
    font-family: monospace;
}
.hiddenView,.printStyle 
{
  font: bold 24px monospace;
  margin: 0px ;
}

.kitchen-table-head{
   /* background-color: #707070;*/
    width: 100%  !important;
    /* height: 50px; */
    color: rgb(0, 0, 0);
    border-style:none;
    border-bottom: 2px dashed black;
    
}
table>.kitchen-table-head>td {
    font: normal normal bolder 27px monospace !important;
    height: 70px;
   /* padding: 20px !important;*/
    font-weight: bolder;
    color: rgb(0, 0, 0);
    border-style:none;
}
table>tbody>tr>td{
    font: normal normal bolder 27px monospace !important;
/* background-color: #707070 !important; */
   /* padding: 20px !important;*/
    font-weight: bolder;
    border-style:none;
    color: rgb(0, 0, 0);
}
.text-strike{
    color: #fff;
		/* padding: 10px; */
		display: inline-block;
		border-radius: 5px;
		animation: blinkingBackground 2s infinite;
}
@keyframes blinkingBackground{
    0%		{ background-color: #10c018;}
    25%		{ background-color: #1056c0;}
    50%		{ background-color: #ef0a1a;}
    75%		{ background-color: #254878;}
    100%	        { background-color: #04a1d5;}
}


@media print{
    .hideNShowOnPirnt{
        display: block;
    }
    .hiddenBtn{
        display: none;
    }
    .rowPad{
        padding: 0;
        /* position: absolute; */
    }
    .tdPad{
        padding: 0 !important;

    }
    .newItemBtn{
        display:none !important;
    }
    .printStyles{margin: 0 !important;
        padding: 0px !important;
    }
    .printStyles>table{
         
            font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
            border-collapse: collapse !important;
            padding: 0 !important;
            /* width: 220px !important; */
            /* padding: 0px !important; */
            margin: 0 !important;
            /* border: 1px solid black ; */
           
    }
    /* table>thead>td{
        font-size: larger !important;
        font-weight: 900 !important;
    } */
    /* .tableHideMargin>p{
        font-size: 22px !important;
        font-weight: bolder !important;
    } */
   
    .printStyles>table>thead>td,.printStyles>table>tbody>tr>td, .tableHideMargin>p>u{
/* font-size: larger !important; */
/*text-align: center !important;*/
/* align-content: flex-start; */
color: black;

/* font-weight: 900; */
    }
    td,th,thead{
        border: 1px solid black;
    }
    .printStyles>td, th {
        border: 1px solid black;
        /* text-align: left; */
        padding: 0px !important;
        font-weight: bold !important;
        font-size: 26px !important;
    
      }
      .kitchen-table-order-nav{
          display: none;
          height: 0;
          margin: 0 !important;
      }
      .tableHideMargin{
          
          display: flex !important;
          /* justify-content: center; */
          flex-direction: row !important;
       }
       .printSize{
           color: black;
       }
       .tableOrderTop{
           max-width: 33.33% !important;
           /* display: flex !important; */
           /* justify-content: center ; */
       }
       .tableOrderTop>p{
           font-size: 18px !important;
           text-align: left !important;
           padding-left: 5%;
       }
.tableOrderContainer{
    margin: 0 !important;
    /* border: 1px solid black !important; */
}

}
.blink_me {
    animation: blinker 1s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }