@font-face{
    font-family:mont-med;
    src:url('../assets/font-family/Montserrat/Montserrat-Medium.ttf')
  }
    /* .MuiTypography-root .MuiTypography-displayBlock{
        font: normal normal normal 16px/25px mont-med
    } */
    /* .MuiTypography-root > .MuiTypography-displayBlock */
    .listItemLink > span {
        font: normal normal normal 14px/25px mont-med !important

    }
    .btnClass:active{
        background-color: red;
    }
    .textClass:active{
        color: white;
    }

    .arrowBack:hover{
fill-opacity: 1;
    }
    .delivery-newOrder-box:hover{
        cursor: pointer;
    }
    .delivery-newOrder-box{
        /* height: 96px;
        margin: 26px 25px 25px;
        padding: 28px 52px 7.5px 53.5px;  
        border-radius: 2px; */
        text-align: center;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        color: #ff0000;
        margin: 0 10px;
    
    }
   
@media print{
    .hiddenDrawer{
        display: none !important;  
        height: 0 !important;     
    }
    .marginEnd{
        margin-left: 0px !important;
        flex-grow:1;
        height: 0 !important;
        
        padding: 0 !important
        
    }
}
/* .hiddenDrawer{
display: none !important;} */