@font-face{
    font-family:mont-med;
    src:url('../../../assets/font-family/Montserrat/Montserrat-Medium.ttf')
  }
.dinning-p-line{padding-top: 5px;
    border-bottom: 4px solid #FF0000;
    text-decoration-line: none;
    /* text-decoration-style: wavy; */
    text-decoration-color: red;
    font: normal normal normal 21px/25px mont-med;
}
.rec-pop-image{
  height:60px;
  margin-left: auto;
}
.add-customer-main{
  margin:20px;
  box-shadow: 0px 8px 23px #E899993B;
  padding: 10px;
}
.add-customer-head{
  /* border: 1px solid black; */
  height: 50px;
}
.customer-list-table-head{
  background-color: #FF0000 !important;
}
table>.table{
  font-size: 18px  !important;
  font-family: mont-med !important;
}
.table>thead{
  color: white !important;
 font: normal normal normal 18px/30px mont-med !important;
}
.table>tbody{
  color: #707070 !important;
 font: normal normal normal 18px/30px mont-med !important;
}
table>.table>thead>th>tr>td{
  font-size: 18px  !important;
  font-family: mont-med !important;
  padding: 1rem !important;
  color: white  !important;
}
/* .customer-list-nav{
  height: 70px;
} */
.customer-popup{

  /* position: absolute; */
  height: 380px;
  width: 600px;
  background-color:white ;
  padding: 0px 20px;
}
.customer-popUp-btn{
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
}
.hideView{
  display: none;
}
.customerPop-style{
  font-weight: normal;
}
@media print{
  .onPrintDine{
    display: none !important;
  }
  .customerPop-style{
    font-weight: bold;
  }
  .rec-pop-image{
    height:220px;
    margin-left:30%;
  }
  .showView{
    display: none;
  }
  .btnOnPrint{
    display: none;
  }
  .hideView{
    margin-top: 0;
    display: block;
    margin-left: 12px;
    max-height: fit-content ;
  }
  .container{
    padding: 0 !important;
    margin: 0 !important;
  }
}


.customer-popup-item{
  display: flex;
  justify-content: space-between;
}