.signIn{
    background-image: url('../assets/images/image.jpg');
    width: 100%;
    height: 100vh;
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main_conatiner{
  height: 570px;
  opacity: 0.60;
  background-color: #000000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  box-sizing: border-box;
}

.Logo_conatiner{
 
  height: 570px;
  background-color: rgba(100, 100, 100, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 
  box-sizing: border-box;
}

.heading{
margin-top: 20%;
padding: 0 0 20px;
text-align: center;
font-size: 22px;
color: white;
}



/* .headingH{
  color: white;
  position : absolute;
  left: 25%;
  top: 50%;
  font-weight: bolder;
} */
.headingH > .heading1{
  font-weight: bolder;
}
/* input form */
.reshead{
  color: white;
  position : absolute;
  left: 25%;
  top: 30%;
  font-weight: bolder;
  
}
.image{
  position : absolute;
   top: 25%;
}

.buttonss:hover{
  background-color: black ;
}

/*  */
@media only screen and (max-width: 1359px) {
  .Logo_conatiner {
    /* background-color: white;
    opacity: 0; */
    display:none
  }
}
@media only screen and (max-width: 1359px) {
  .image {
display: none;
}
  }
  @media only screen and (max-width: 1359px) {
    /* .main_conatiner{
     
      width: 400px;
      height: 570px;
      opacity: 0.60;
   
      background-color: #000000;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      position : absolute; */
      /* left: 50%;
      top: 50%;
      transform: translate(-50%,-50%); */
      /* box-sizing: border-box;
    } */
    }
    @media only screen and (max-width: 1359px) {
      .signIn{
        background-image: url('../assets/images/image.jpg');
        min-height: 100vh;
        background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    margin:0 !important;
    padding: 0;
    opacity: 1;
    
   
    }
      }
    