.invoice-table-head{
    background-color: red;
    color: white;
}

.storeDash-upper{
    display: flex;
}
.storeDash-upper-right{
    width: 100%;
}
.storeDash-upper-right-bottom{
    display:flex;
    justify-content: space-around;
}
table>tbody>tr>td{
    /*font: normal normal normal 17px monospace !important;*/
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  
}
tr {
    height: 10px;
  }
  
  tr td {
     height: auto !important;
  }
  #product-details-list-table{
      background-color: white !important;
  }
  table>tbody>tr>td>span{
    font: normal normal normal 14px monospace !important; 
  }