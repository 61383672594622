@font-face{
    font-family:mont-med;
    src:url('../../../assets/font-family/Montserrat/Montserrat-Medium.ttf')
  }
.kitchen-list-table-head{
    background-color: #707070;
    color: white;
}
.kitchen-orders-nav{
    display: flex;
    height: 50px;
    justify-content: space-between;
    background-color: white;
}
.kitchen-table-order-nav{
    display: flex;
    justify-content: space-between;
}
.w-100{
  font-family: monospace;
}
.hiddenView,.printStyle 
{
  font: bold 24px monospace;
  margin: 0px ;
}
.kitchen-order-table{
    width: 100%;
}
.kitchen-table-head{
    /* background-color: #707070;*/
    width: 100%  !important;
    /* height: 50px; */
    color: rgb(0, 0, 0);
    border-style:none;
    border-bottom: 2px dashed black;
}
table>.kitchen-table-head>td {
    font: normal normal bolder 27px monospace !important;
    height: 70px;
   /* padding: 20px !important;*/
    font-weight: bolder;
    border-style:none;
    color: rgb(0, 0, 0);
}
table>tbody>tr>td{
    font: normal normal bolder 27px  monospace !important;
/* background-color: #707070 !important; */
   /* padding: 20px !important;*/
    font-weight: bolder;
    border-style:none;
    color: rgb(0, 0, 0);
}
.cards{
    display: flex;
    flex-wrap: wrap;
}
.MuiCard-root
{
    width: 200px;
    margin: 10px;
}
.MuiCardMedia-root{
    height: 140px;
}

.dinning-p-line{padding-top: 5px;
    border-bottom: 4px solid #FF0000;
    text-decoration-line: none;
    /* text-decoration-style: wavy; */
    text-decoration-color: red;
    font: normal normal normal 21px/25px mont-med;
}
.add-customer-main{
  margin:20px;
  box-shadow: 0px 8px 23px #E899993B;
  padding: 10px;
}
.add-customer-head{
  /* border: 1px solid black; */
  height: 50px;
}
.customer-list-table-head{
  background-color: #FF0000 !important;
}
table>.table{
  font-size: 14px  !important;
  font-family: mont-med !important;
}
.table>thead{
  color: white !important;
 font: normal normal normal 14px/16px mont-med !important;
}
.table>tbody{
  color: #707070 !important;
 font: normal normal normal 12px/14px mont-med !important;
}
table>.table>thead>th>tr>td{
  font-size: 14px  !important;
  font-family: mont-med !important;
  padding: 1rem !important;
  color: white  !important;
}
/* .customer-list-nav{
  height: 70px;
} */
.customer-popup{

  /* position: absolute; */
  height: 380px;
  width: 600px;
  background-color:white ;
}
.customer-popup-item{
  display: flex;
  justify-content: space-between;
}


.cashierBillDetail:hover{
cursor: pointer;
opacity: 0.5;

}

.print-receipt{
  display: none;
}
.printStyle{
  display: none;
}
@media print{
  .printStyle{
    display: grid;
  }
  .cashierTableOrder{
    display: none !important;
  }
  .print-receipt{
    display: grid;
  }
  .hiddenBtn{
    display: none;
}
.rowPad{
    padding: 0;
    /* position: absolute; */
}
.tdPad{
    padding: 0 !important;

}
.printStyle{margin: 0 !important;
    padding: 0px !important;
}
.printStyle>table{
     
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
        border-collapse: collapse !important;
        padding: 0 !important;
        /* width: 220px !important; */
        /* padding: 0px !important; */
        margin: 0 !important;
        /* border: 1px solid black ; */
       
}
/* table>thead>td{
    font-size: larger !important;
    font-weight: 900 !important;
} */
/* .tableHideMargin>p{
    font-size: 22px !important;
    font-weight: bolder !important;
} */

.printStyle>table>thead>td,.printStyle>table>tbody>tr>td, .tableHideMargin>p>u{
/* font-size: larger !important; */
/*text-align: center !important;*/
/* align-content: flex-start; */
color: black;

/* font-weight: 900; */
}
td,th,thead{
    border: 1px solid black;
}
.printStyle>td, th {
    border: 1px solid black;
    /* text-align: left; */
    padding: 0px !important;
    font-weight: bold !important;
    font-size: 26px !important;

  }
  .kitchen-table-order-nav{
      display: none;
      height: 0;
      margin: 0 !important;
  }
  .tableHideMargin{
      
      /* display: flex !important; */
      /* justify-content: center; */
      /* flex-direction: row !important; */
      font: bold 24px monospace;
   }
   .printSize{
       color: black;
   }
   .tableOrderTop{
       max-width: 33.33% !important;
       /* display: flex !important; */
       /* justify-content: center ; */
   }
   .tableOrderTop>p{
       font-size: 18px !important;
       text-align: left !important;
       padding-left: 5%;
   }
.tableOrderContainer{
margin: 0 !important;
/* border: 1px solid black !important; */
}
  
}